(function($) {
  $(document).ready(
        $("dt[class*='Catalog__Reveal_item']").click(function() {
            $question = $(this);
            $answer = $question.next("dd[class*='Catalog__Reveal_item']");
            $answer.animate({
                height: "toggle"
            }, 150, function() { $question.toggleClass('revealed'); });
        })
    );
})(jQuery); // Fully reference jQuery after this point.