/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // This opens the menu on mobile
        $('.Button__Button_site__header').click(function() {
            $($(this).data('open')).toggleClass('open');
            $('body').toggleClass('no-scroll-mobile');
        });

        $('.submenu_toggle_checkbox').change(function() {
            if($(this).prop("checked")) {
              $(this).closest('ul').addClass('activated').scrollTop(0);
            } else {
              $(this).closest('ul').removeClass('activated');
            }
        });

        // This opens the search bar on desktop
        $('[data-action="open-search"]').click(function(e) {
            e.preventDefault();
            $(this).parent().addClass('open');
            $(this).next('form').find('input').focus();
        });
        $('[data-action="close-search"]').click(function() {
            $(this).parent().removeClass('open');
        });

        // This opens dropdowns
        $('[data-style="dropdown"] li:first-child').click(function(e) {
            e.preventDefault();
            $(this).toggleClass('open');
            $(this).siblings('li').toggle({duration: 200, queue: false});
        });

        // This tracks phone number clicks
        $("[href^='mailto:']").click(function() {
          $self = $(this);

          category = 'Email Click Tracking';
          action = 'Click/Touch';
          label = $self.attr('href').substring('mailto:'.length);

          ga('send', 'event', category, action, label);
        });

        // This tracks phone number clicks
        $("[href^='tel:']").click(function() {
          category = 'Phone Call Tracking';
          action = 'Click/Touch';
          label = 'generic';
          $self = $(this);

          // Header
          if($self.hasClass('Block__Consult_phone')) {
            label = 'Header';
          }
          // We Can Help Call Out
          if($self.closest('.Keel__Keel').length > 0) {
            label = 'we-can-help';
          }

          // Footer
          if($self.parent('[itemprop="telephone"]').length > 0) {
            label = 'footer';
          }

          // Footer (fax)
          if($self.parent('[itemprop="faxNumber"]').length > 0) {
            label = 'footer/fax';
          }

          // Contact
          if($self.parent('.contact_vectors_phone').length > 0 && $self.data('parent') === 'phone') {
            label = 'contact-page';
          }

          // Contact (fax)
          if($self.parent('.contact_vectors_phone').length > 0 && $self.data('parent') === 'fax') {
            label = 'contact-page/fax';
          }

          ga('send', 'event', category, action, label);
        });

        // On Call display control
        console.log('init');
        $('body').on('click', '.on-call.icon-size', function() {
          $(this).removeClass('icon-size');
        });
        $('body').on('click', '.on-call-close', function() {
          $('.on-call').addClass('icon-size');
        });

        //On Call cookie check/set
        if (!Cookies.get('oncallseen')) {
          // no cookie telling us the user has seen the oncall within the last 24 hours
          console.log('no cookie');
          setTimeout(function(){
            $('.on-call').removeClass('icon-size');
            Cookies.set('oncallseen', '1', { expires: 1 });
          }, 5000);
        } else {
          console.log('yes cookie');
        }

      },
      finalize: function() {
        objectFitImages();
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'author': {
      init: function() {

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
